@media screen and (max-width: 770px) {
  .sidepar {
    position: fixed;
    transform: translateX(-100%);
    transition: all ease-in-out 0.3s;
    width: 100%;
    z-index: 20;
  }
  .active {
    transform: translateX(0%);
  }

  .noScroll {
    overflow: hidden;
  }
}

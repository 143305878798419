/* Hides the increment and decrement buttons on a number input */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
/* Default checkbox style */
input[type='checkbox'] {
  accent-color: transparent; /* Default hidden style */
}

/* Style for checked state */
input[type='checkbox']:checked {
  accent-color: yellow;
}

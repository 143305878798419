@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'leaflet/dist/leaflet.css';

.overflow-auto {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #264e97 #002346; /* Firefox: thumb and track color */
}

.overflow-auto::-webkit-scrollbar {
  width: 6px; /* For horizontal scroll, use height */
  height: 6px; /* For vertical scroll */
  border-radius: 20px;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #264e97;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #002346;
  border-radius: 20px;
  border: none;
}
